import axios from 'axios'
import qs from 'qs'
import store from '@/store/index'
import { Toast } from 'vant'

const http = (params) => {
    const { host, method, postType, path, data, isCatch, loading, userToken } = params
    return new Promise((resolve, reject) => {
        const headers = {
            ['content-Type']: 'application/json',
            module: 'cake',
            channel: 'website',
        }

        const { userInfo } = store.state.user
        if ((userInfo && userInfo.token) || userToken) {
            headers['token'] = userInfo ? userInfo.token : userToken
        }
        if (postType === 'form') {
            headers['content-Type'] = 'application/x-www-form-urlencoded'
        }
        if (loading.status) {
            Toast.loading({ duration: 0, message: loading.text || 'Loading...' })
        }
        axios({
            method,
            url: host + path,
            headers: headers,
            data,
        })
            .then((res) => {
                if (res.status == 200 && res.data.code === 200) {
                    if (loading.status) {
                        Toast.clear()
                    }
                    resolve(res.data)
                } else if (res.data.code === 401) {
                    Toast('登录已过期，请重新登录')
                    setTimeout(() => {
                        store.dispatch('user/logout')
                    }, 1000)
                } else {
                    if (isCatch) {
                        reject(res)
                        if (loading.status) {
                            Toast.clear()
                        }
                    } else {
                        Toast(res.data.message)
                    }
                }
            })
            .catch((err) => {
                if (isCatch) {
                    reject(err)
                } else {
                    Toast('网络异常，请检查网络是否正常')
                }
            })
    })
}

export const nuwa_post = (params) => {
    const { path, data, isCatch, loading = { status: true, text: '' } } = params
    return http({
        host: store.state.global.SEVER_PATH_NUWA,
        method: 'POST',
        postType: '',
        path,
        data,
        isCatch,
        loading,
    })
}

export const nuwa_post_form = (params) => {
    const { path, data, isCatch, loading = { status: true, text: '' } } = params
    return http({
        host: store.state.global.SEVER_PATH_NUWA,
        method: 'POST',
        postType: 'form',
        path,
        data: qs.stringify(data),
        isCatch,
        loading,
    })
}

export const begonia_post = (params) => {
    const { path, data, isCatch, loading = { status: true, text: '' } } = params
    return http({
        host: store.state.global.SEVER_PATH_BEGONIA,
        method: 'POST',
        postType: '',
        path,
        data,
        isCatch,
        loading,
    })
}

export const begonia_post_form = (params) => {
    const { path, data, isCatch, loading = { status: true, text: '' }, userToken } = params
    return http({
        host: store.state.global.SEVER_PATH_BEGONIA,
        method: 'POST',
        postType: 'form',
        path,
        data: qs.stringify(data),
        isCatch,
        loading,
        userToken,
    })
}

export const cupid_post = (params) => {
    const { path, data, isCatch, loading = { status: true, text: '' } } = params
    return http({
        host: store.state.global.SEVER_PATH_CUPID,
        method: 'POST',
        postType: '',
        path,
        data,
        isCatch,
        loading,
    })
}

export const cupid_post_form = (params) => {
    const { path, data, isCatch, loading = { status: true, text: '' }, userToken } = params
    return http({
        host: store.state.global.SEVER_PATH_CUPID,
        method: 'POST',
        postType: 'form',
        path,
        data: qs.stringify(data),
        isCatch,
        loading,
        userToken,
    })
}
